export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "eu-central-1",
    API_URL: "https://3h7n636xek.execute-api.eu-central-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_Cw3IuWZM5",
    APP_CLIENT_ID: "6gdgegofnlrhiiq6cku2je0jp1",
    IDENTITY_POOL_ID: "eu-central-1:ffca3bb4-cbf7-49c1-ae0c-3ac491cae0c5"
  }
};